import { useSearchParams } from 'next/navigation'
import { useState } from 'react'
import Swal, { SweetAlertOptions } from 'sweetalert2'

export const useGetUtmQueryParams = () => {
  try {
    const searchParams = useSearchParams()
    return {
      utm_source: searchParams?.get('utm_source'),
      utm_medium: searchParams?.get('utm_medium'),
      utm_campaign: searchParams?.get('utm_campaign')
    }
  } catch (error) {
    return {}
  }
}

export const useSubmitForm = ({
  service,
  aditionalPayloadData,
  onErrorCallback,
  onSuccessCallback
}: {
  service: (data: Record<string, any>) => Promise<void>
  aditionalPayloadData: Record<string, any>
  onErrorCallback: () => any
  onSuccessCallback?: () => any
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async (data: Record<string, any>, reset: () => any) => {
    if (isLoading) return
    setIsLoading(true)

    let operationSuccess = true
    try {
      await service({
        ...aditionalPayloadData,
        ...data
      })
    } catch (error) {
      operationSuccess = false
    } finally {
      reset()
      setIsLoading(false)
    }

    if (!operationSuccess && onErrorCallback) {
      onErrorCallback()
      return
    }
    if (onSuccessCallback) onSuccessCallback()
  }
  return { handleSubmit, isLoading }
}

export const showUIAlert = (SweetAlertConfiguration: SweetAlertOptions) => {
  void Swal.fire(SweetAlertConfiguration)
}
