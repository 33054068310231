/* eslint-disable react/no-unstable-nested-components */
import React, { useState, ChangeEvent } from 'react'
import FormGroup from '@/components/atoms/forms/FormGroup'
import InputErrorText from '@/components/atoms/forms/InputErrorText'
import InputGroup from '@/components/atoms/forms/InputGroup'
import { Label } from '@/components/atoms/forms/Label'
import SelectPhone from './SelectPhone'

interface FormSelectPhoneProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string | React.ReactNode
  placeholder: string
  value: string | number
  isValid?: boolean
  errorText?: string
  required?: boolean
  onChange: (value: any) => any
}

export const FormSelectPhone = React.forwardRef(
  (
    {
      name,
      label = '',
      placeholder,
      value,
      isValid = true,
      required,
      className,
      errorText,
      onChange
    }: FormSelectPhoneProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event)
    }

    return (
      <FormGroup className={className}>
        <Label isFocused={isFocused} isValid={isValid} hidden={Boolean(label)} htmlFor={name}>
          {label}
        </Label>
        <InputGroup>
          <SelectPhone
            id={name}
            name={name}
            value={value}
            isValid={isValid}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleOnChange}
            required={required}
            placeholder={isFocused ? '' : required ? '* ' + placeholder : placeholder}
          />
          <InputErrorText>{errorText}</InputErrorText>
        </InputGroup>
      </FormGroup>
    )
  }
)

FormSelectPhone.displayName = 'FormSelectPhone'
export default FormSelectPhone
