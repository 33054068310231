type Flag = [string, string, string]
type Flags = Record<string, Flag>

export const FLAGS: Flags = {
  af: ['af', 'Afghanistan', '+93'],
  al: ['al', 'Albania', '+355'],
  dz: ['dz', 'Algeria', '+213'],
  ad: ['ad', 'Andorra', '+376'],
  ao: ['ao', 'Angola', '+244'],
  ag: ['ag', 'Antigua and Barbuda', '+1268'],
  ar: ['ar', 'Argentina', '+54'],
  am: ['am', 'Armenia', '+374'],
  aw: ['aw', 'Aruba', '+297'],
  au: ['au', 'Australia', '+61'],
  at: ['at', 'Austria', '+43'],
  az: ['az', 'Azerbaijan', '+994'],
  bs: ['bs', 'Bahamas', '+1242'],
  bh: ['bh', 'Bahrain', '+973'],
  bd: ['bd', 'Bangladesh', '+880'],
  bb: ['bb', 'Barbados', '+1246'],
  by: ['by', 'Belarus', '+375'],
  be: ['be', 'Belgium', '+32'],
  bz: ['bz', 'Belize', '+501'],
  bj: ['bj', 'Benin', '+229'],
  bt: ['bt', 'Bhutan', '+975'],
  bo: ['bo', 'Bolivia', '+591'],
  ba: ['ba', 'Bosnia and Herzegovina', '+387'],
  bw: ['bw', 'Botswana', '+267'],
  br: ['br', 'Brazil', '+55'],
  io: ['io', 'British Indian Ocean Territory', '+246'],
  bn: ['bn', 'Brunei', '+673'],
  bg: ['bg', 'Bulgaria', '+359'],
  bf: ['bf', 'Burkina Faso', '+226'],
  bi: ['bi', 'Burundi', '+257'],
  kh: ['kh', 'Cambodia', '+855'],
  cm: ['cm', 'Cameroon', '+237'],
  cv: ['cv', 'Cape Verde', '+238'],
  bq: ['bq', 'Caribbean Netherlands', '+599'],
  cf: ['cf', 'Central African Republic', '+236'],
  td: ['td', 'Chad', '+235'],
  cl: ['cl', 'Chile', '+56'],
  cn: ['cn', 'China', '+86'],
  co: ['co', 'Colombia', '+57'],
  km: ['km', 'Comoros', '+269'],
  cd: ['cd', 'Congo', '+243'],
  cg: ['cg', 'Congo', '+242'],
  cr: ['cr', 'Costa Rica', '+506'],
  ci: ['ci', 'Côte d’Ivoire', '+225'],
  hr: ['hr', 'Croatia', '+385'],
  cu: ['cu', 'Cuba', '+53'],
  cw: ['cw', 'Curaçao', '+599'],
  cy: ['cy', 'Cyprus', '+357'],
  cz: ['cz', 'Czech Republic', '+420'],
  dk: ['dk', 'Denmark', '+45'],
  dj: ['dj', 'Djibouti', '+253'],
  dm: ['dm', 'Dominica', '+1767'],
  do: ['do', 'Dominican Republic', '+1'],
  ec: ['ec', 'Ecuador', '+593'],
  eg: ['eg', 'Egypt', '+20'],
  sv: ['sv', 'El Salvador', '+503'],
  gq: ['gq', 'Equatorial Guinea', '+240'],
  er: ['er', 'Eritrea', '+291'],
  ee: ['ee', 'Estonia', '+372'],
  et: ['et', 'Ethiopia', '+251'],
  fj: ['fj', 'Fiji', '+679'],
  fi: ['fi', 'Finland', '+358'],
  fr: ['fr', 'France', '+33'],
  gf: ['gf', 'French Guiana', '+594'],
  pf: ['pf', 'French Polynesia', '+689'],
  ga: ['ga', 'Gabon', '+241'],
  gm: ['gm', 'Gambia', '+220'],
  ge: ['ge', 'Georgia', '+995'],
  de: ['de', 'Germany', '+49'],
  gh: ['gh', 'Ghana', '+233'],
  gr: ['gr', 'Greece', '+30'],
  gd: ['gd', 'Grenada', '+1473'],
  gp: ['gp', 'Guadeloupe', '+590'],
  gu: ['gu', 'Guam', '+1671'],
  gt: ['gt', 'Guatemala', '+502'],
  gn: ['gn', 'Guinea', '+224'],
  gw: ['gw', 'Guinea-Bissau', '+245'],
  gy: ['gy', 'Guyana', '+592'],
  ht: ['ht', 'Haiti', '+509'],
  hn: ['hn', 'Honduras', '+504'],
  hk: ['hk', 'Hong Kong', '+852'],
  hu: ['hu', 'Hungary', '+36'],
  is: ['is', 'Iceland', '+354'],
  in: ['in', 'India', '+91'],
  id: ['id', 'Indonesia', '+62'],
  ir: ['ir', 'Iran', '+98'],
  iq: ['iq', 'Iraq', '+964'],
  ie: ['ie', 'Ireland', '+353'],
  il: ['il', 'Israel', '+972'],
  it: ['it', 'Italy', '+39'],
  jm: ['jm', 'Jamaica', '+1876'],
  jp: ['jp', 'Japan', '+81'],
  jo: ['jo', 'Jordan', '+962'],
  kz: ['kz', 'Kazakhstan', '+7'],
  ke: ['ke', 'Kenya', '+254'],
  ki: ['ki', 'Kiribati', '+686'],
  xk: ['xk', 'Kosovo', '+383'],
  kw: ['kw', 'Kuwait', '+965'],
  kg: ['kg', 'Kyrgyzstan', '+996'],
  la: ['la', 'Laos', '+856'],
  lv: ['lv', 'Latvia', '+371'],
  lb: ['lb', 'Lebanon', '+961'],
  ls: ['ls', 'Lesotho', '+266'],
  lr: ['lr', 'Liberia', '+231'],
  ly: ['ly', 'Libya', '+218'],
  li: ['li', 'Liechtenstein', '+423'],
  lt: ['lt', 'Lithuania', '+370'],
  lu: ['lu', 'Luxembourg', '+352'],
  mo: ['mo', 'Macau', '+853'],
  mk: ['mk', 'Macedonia', '+389'],
  mg: ['mg', 'Madagascar', '+261'],
  mw: ['mw', 'Malawi', '+265'],
  my: ['my', 'Malaysia', '+60'],
  mv: ['mv', 'Maldives', '+960'],
  ml: ['ml', 'Mali', '+223'],
  mt: ['mt', 'Malta', '+356'],
  mh: ['mh', 'Marshall Islands', '+692'],
  mq: ['mq', 'Martinique', '+596'],
  mr: ['mr', 'Mauritania', '+222'],
  mu: ['mu', 'Mauritius', '+230'],
  mx: ['mx', 'Mexico', '+52'],
  fm: ['fm', 'Micronesia', '+691'],
  md: ['md', 'Moldova', '+373'],
  mc: ['mc', 'Monaco', '+377'],
  mn: ['mn', 'Mongolia', '+976'],
  me: ['me', 'Montenegro', '+382'],
  ma: ['ma', 'Morocco', '+212'],
  mz: ['mz', 'Mozambique', '+258'],
  mm: ['mm', 'Myanmar', '+95'],
  na: ['na', 'Namibia', '+264'],
  nr: ['nr', 'Nauru', '+674'],
  np: ['np', 'Nepal', '+977'],
  nl: ['nl', 'Netherlands', '+31'],
  nc: ['nc', 'New Caledonia', '+687'],
  nz: ['nz', 'New Zealand', '+64'],
  ni: ['ni', 'Nicaragua', '+505'],
  ne: ['ne', 'Niger', '+227'],
  ng: ['ng', 'Nigeria', '+234'],
  kp: ['kp', 'North Korea', '+850'],
  no: ['no', 'Norway', '+47'],
  om: ['om', 'Oman', '+968'],
  pk: ['pk', 'Pakistan', '+92'],
  pw: ['pw', 'Palau', '+680'],
  ps: ['ps', 'Palestine', '+970'],
  pa: ['pa', 'Panama', '+507'],
  pg: ['pg', 'Papua New Guinea', '+675'],
  py: ['py', 'Paraguay', '+595'],
  pe: ['pe', 'Peru', '+51'],
  ph: ['ph', 'Philippines', '+63'],
  pl: ['pl', 'Poland', '+48'],
  pt: ['pt', 'Portugal', '+351'],
  pr: ['pr', 'Puerto Rico', '+1'],
  qa: ['qa', 'Qatar', '+974'],
  re: ['re', 'Réunion', '+262'],
  ro: ['ro', 'Romania', '+40'],
  ru: ['ru', 'Russia', '+7'],
  rw: ['rw', 'Rwanda', '+250'],
  kn: ['kn', 'Saint Kitts and Nevis', '+1869'],
  lc: ['lc', 'Saint Lucia', '+1758'],
  vc: ['vc', 'Saint Vincent and the Grenadines', '+1784'],
  ws: ['ws', 'Samoa', '+685'],
  sm: ['sm', 'San Marino', '+378'],
  st: ['st', 'São Tomé and Príncipe', '+239'],
  sa: ['sa', 'Saudi Arabia', '+966'],
  sn: ['sn', 'Senegal', '+221'],
  rs: ['rs', 'Serbia', '+381'],
  sc: ['sc', 'Seychelles', '+248'],
  sl: ['sl', 'Sierra Leone', '+232'],
  sg: ['sg', 'Singapore', '+65'],
  sk: ['sk', 'Slovakia', '+421'],
  si: ['si', 'Slovenia', '+386'],
  sb: ['sb', 'Solomon Islands', '+677'],
  so: ['so', 'Somalia', '+252'],
  za: ['za', 'South Africa', '+27'],
  kr: ['kr', 'South Korea', '+82'],
  ss: ['ss', 'South Sudan', '+211'],
  es: ['es', 'Spain', '+34'],
  lk: ['lk', 'Sri Lanka', '+94'],
  sd: ['sd', 'Sudan', '+249'],
  sr: ['sr', 'Suriname', '+597'],
  sz: ['sz', 'Swaziland', '+268'],
  se: ['se', 'Sweden', '+46'],
  ch: ['ch', 'Switzerland', '+41'],
  sy: ['sy', 'Syria', '+963'],
  tw: ['tw', 'Taiwan', '+886'],
  tj: ['tj', 'Tajikistan', '+992'],
  tz: ['tz', 'Tanzania', '+255'],
  th: ['th', 'Thailand', '+66'],
  tl: ['tl', 'Timor-Leste', '+670'],
  tg: ['tg', 'Togo', '+228'],
  to: ['to', 'Tonga', '+676'],
  tt: ['tt', 'Trinidad and Tobago', '+1868'],
  tn: ['tn', 'Tunisia', '+216'],
  tr: ['tr', 'Turkey', '+90'],
  tm: ['tm', 'Turkmenistan', '+993'],
  tv: ['tv', 'Tuvalu', '+688'],
  ug: ['ug', 'Uganda', '+256'],
  ua: ['ua', 'Ukraine', '+380'],
  ae: ['ae', 'United Arab Emirates', '+971'],
  gb: ['gb', 'United Kingdom', '+44'],
  uy: ['uy', 'Uruguay', '+598'],
  uz: ['uz', 'Uzbekistan', '+998'],
  vu: ['vu', 'Vanuatu', '+678'],
  va: ['va', 'Vatican City', '+39'],
  ve: ['ve', 'Venezuela', '+58'],
  vn: ['vn', 'Vietnam', '+84'],
  ye: ['ye', 'Yemen', '+967'],
  zm: ['zm', 'Zambia', '+260'],
  zw: ['zw', 'Zimbabwe', '+263']
}

export const ALL_FLAGS_KEYS = [
  'af',
  'al',
  'dz',
  'ad',
  'ao',
  'ag',
  'ar',
  'am',
  'aw',
  'au',
  'at',
  'az',
  'bs',
  'bh',
  'bd',
  'bb',
  'by',
  'be',
  'bz',
  'bj',
  'bt',
  'bo',
  'ba',
  'bw',
  'br',
  'io',
  'bn',
  'bg',
  'bf',
  'bi',
  'kh',
  'cm',
  'cv',
  'bq',
  'cf',
  'td',
  'cl',
  'cn',
  'co',
  'km',
  'cd',
  'cg',
  'cr',
  'ci',
  'hr',
  'cu',
  'cw',
  'cy',
  'cz',
  'dk',
  'dj',
  'dm',
  'do',
  'ec',
  'eg',
  'sv',
  'gq',
  'er',
  'ee',
  'et',
  'fj',
  'fi',
  'fr',
  'gf',
  'pf',
  'ga',
  'gm',
  'ge',
  'de',
  'gh',
  'gr',
  'gd',
  'gp',
  'gu',
  'gt',
  'gn',
  'gw',
  'gy',
  'ht',
  'hn',
  'hk',
  'hu',
  'is',
  'in',
  'id',
  'ir',
  'iq',
  'ie',
  'il',
  'it',
  'jm',
  'jp',
  'jo',
  'kz',
  'ke',
  'ki',
  'xk',
  'kw',
  'kg',
  'la',
  'lv',
  'lb',
  'ls',
  'lr',
  'ly',
  'li',
  'lt',
  'lu',
  'mo',
  'mk',
  'mg',
  'mw',
  'my',
  'mv',
  'ml',
  'mt',
  'mh',
  'mq',
  'mr',
  'mu',
  'mx',
  'fm',
  'md',
  'mc',
  'mn',
  'me',
  'ma',
  'mz',
  'mm',
  'na',
  'nr',
  'np',
  'nl',
  'nc',
  'nz',
  'ni',
  'ne',
  'ng',
  'kp',
  'no',
  'om',
  'pk',
  'pw',
  'ps',
  'pa',
  'pg',
  'py',
  'pe',
  'ph',
  'pl',
  'pt',
  'pr',
  'qa',
  're',
  'ro',
  'ru',
  'rw',
  'kn',
  'lc',
  'vc',
  'ws',
  'sm',
  'st',
  'sa',
  'sn',
  'rs',
  'sc',
  'sl',
  'sg',
  'sk',
  'si',
  'sb',
  'so',
  'za',
  'kr',
  'ss',
  'es',
  'lk',
  'sd',
  'sr',
  'sz',
  'se',
  'ch',
  'sy',
  'tw',
  'tj',
  'tz',
  'th',
  'tl',
  'tg',
  'to',
  'tt',
  'tn',
  'tr',
  'tm',
  'tv',
  'ug',
  'ua',
  'ae',
  'gb',
  'uy',
  'uz',
  'vu',
  'va',
  've',
  'vn',
  'ye',
  'zm',
  'zw'
]
