// eslint-disable-next-line import/no-duplicates
import styles from './SelectPhone.module.scss'
// eslint-disable-next-line import/no-duplicates
import './SelectPhone.module.scss'
import React, { useState, useEffect, ChangeEvent } from 'react'
import { BasicSelect, ISelectOption } from './BasicSelect'
import { ALL_FLAGS_KEYS, FLAGS } from './SelectPhone.const'
import { classNames } from '@/components/shared/classNames'

import FormInput from '@/components/atoms/inputs/FormInput'

type FlagOption = ISelectOption & { flag?: string }

const OptionTemplate = ({ option }: { option: FlagOption }) => (
  <div className={styles['select-phone--option']}>
    <div className={classNames(styles['flag-icon'], styles[`${option.flag ?? ''}`], 'pb-1')} />
    {option.label} {option.value}
  </div>
)

const SelectedTemplate = ({ option }: { option: FlagOption }) => (
  <div className={classNames(styles['select-phone--selected'])}>
    <div className={classNames(styles['flag-icon'], styles[`${option.flag ?? ''}`])} />
    {option.value}
  </div>
)

export interface SelectPhoneProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode
  required?: boolean
  flagList?: string[]
  placeholder?: string
  name: string
  className?: string
  isValid?: boolean
  errorText?: string
  inputmode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url'
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any
}

export const SelectPhone = ((
  {
    label,
    flagList = ALL_FLAGS_KEYS,
    placeholder = 'Default placeholder',
    isValid = true,
    value,
    onChange,
    onBlur,
    name,
    className
  }: SelectPhoneProps,
  ref: any
) => {
  const options: FlagOption[] = flagList.map(flag => {
    return {
      value: FLAGS[flag][2],
      label: FLAGS[flag][1].toUpperCase(),
      flag: FLAGS[flag][0]
    }
  })
  const [iniPrefix, iniPhone] = String(value)?.split(' ') ?? ['', '']
  const [prefix, setPrefix] = useState(iniPrefix || '+52')
  const [phone, setPhone] = useState(iniPhone ?? '')
  const [fullPhone, setFullPhone] = useState('')

  const hadleOnChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(String(event.target.value).replace(/\D/g, ''))
  }

  const hadleOnChangePrefix = (eventValue: string) => {
    setPrefix(String(eventValue))
  }

  useEffect(() => {
    setFullPhone(`${prefix} ${String(phone).replace(/\D/g, '')}`)
  }, [prefix, phone])

  useEffect(() => {
    if (String(value).length !== 0) return
    setPrefix('+52')
    setPhone('')
  }, [value])

  useEffect(() => {
    const emitEvent = {
      target: {
        value: fullPhone
      }
    }
    onChange?.(emitEvent as ChangeEvent<HTMLInputElement>)
  }, [fullPhone, onChange])

  return (
    <div className={classNames(className, styles['select-phone'], isValid ? '' : styles['select-phone--invalid'])}>
      <BasicSelect
        id={`${name}-prefix`}
        options={options}
        value={prefix}
        isValid={isValid}
        onChange={hadleOnChangePrefix}
        SelectedTemplate={(option: FlagOption) => <SelectedTemplate option={option} />}
        OptionTemplate={(option: FlagOption) => <OptionTemplate option={option} />}
        name={`${name}-prefix`}
        className={className}
      />
      <FormInput
        type="text"
        name={name}
        label={label}
        placeholder={placeholder}
        value={phone}
        isValid={isValid}
        onChange={hadleOnChangePhone}
      />
    </div>
  )
}) as React.ForwardRefExoticComponent<SelectPhoneProps & React.RefAttributes<HTMLInputElement>>

SelectPhone.displayName = 'SelectPhone'
export default SelectPhone
