'use client'
import React, { useState } from 'react'
import style from './FormSelect.module.scss'
import FormGroup from '@/components/atoms/forms/FormGroup'
import { Label } from '@/components/atoms/forms/Label'
import InputGroup from '@/components/atoms/forms/InputGroup'
import { classNames } from '@/components/shared/classNames'
import InputErrorText from '@/components/atoms/forms/InputErrorText'
import { BasicSelect, ISelectOption } from '@/components/atoms/inputs/BasicSelect'

interface FormSelectProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string | React.ReactNode
  placeholder: string
  value: string | number
  defaultValue?: string | number
  isValid?: boolean
  options: ISelectOption[]
  errorText?: string
  required?: boolean
  onChange: (value: any) => any
}

const FormSelect = React.forwardRef(
  (
    {
      name,
      label,
      placeholder,
      value,
      defaultValue,
      isValid = true,
      options,
      required,
      className,
      errorText,
      onChange
    }: FormSelectProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    return (
      <FormGroup className={className}>
        <Label isFocused={isFocused} isValid={isValid} hidden={Boolean(label)} htmlFor={name}>
          {label}
        </Label>
        <InputGroup>
          <BasicSelect
            className={classNames(style.input, isValid ? '' : style['input--invalid'])}
            id={name}
            name={name}
            value={value ?? defaultValue}
            isValid={isValid}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            required={required}
            placeholder={isFocused ? '' : required ? '* ' + placeholder : placeholder}
            options={options}
          />
          <InputErrorText>{errorText}</InputErrorText>
        </InputGroup>
      </FormGroup>
    )
  }
)

FormSelect.displayName = 'FormSelect'
export default FormSelect
