export interface ISelectOption {
  value: string
  label: string
}

export interface IInputConfiguration {
  type: string
  subtype: string
  defaultValue?: string | number | boolean
  name: string
  label: string
  className: string
  placeholder: string
  required: boolean
  disabled?: boolean
  showLabel: boolean
  validations: {
    required?: { value: boolean; message: string }
    minLength?: { value: number; message: string }
    maxLength?: { value: number; message: string }
    pattern?: { value: RegExp; message: string }
  }
  options?: ISelectOption[]
}

export interface IInputConfigurationPartial {
  type?: string
  subtype?: string
  defaultValue?: string | number | boolean
  name: string
  label?: string
  className?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  showLabel?: boolean
  validations?: {
    required?: { value: boolean; message: string }
    minLength?: { value: number; message: string }
    maxLength?: { value: number; message: string }
    pattern?: { value: RegExp; message: string }
  }
  options?: ISelectOption[]
}

export type InputName =
  | 'full_name'
  | 'name'
  | 'last_name'
  | 'company'
  | 'position'
  | 'phone'
  | 'fix_number_phone'
  | 'email'
  | 'message'
  | 'role'
  | 'residence'
  | 'accept_terms'
  | 'cv_resume'
  | 'salary_expectation'
  | 'identification_card'
  | 'years_of_experience'
  | 'has_more_than_2_years_of_experience'
  | 'aimed_at'
  | 'username'
  | 'password'
  | 'oldPassword'
  | 'newPassword'
  | 'token'

export const AvailableInputConfigurations: { [key in InputName]: IInputConfiguration } = {
  full_name: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'full_name',
    label: 'Nombre y apellidos',
    className: 'flex-1 min-w-32',
    placeholder: 'Nombre y apellidos',
    required: false,
    showLabel: false,
    validations: {}
  },
  name: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'name',
    label: 'Nombre(s)',
    className: 'flex-1 min-w-32',
    placeholder: 'Nombre(s)',
    required: true,
    showLabel: false,
    validations: {
      minLength: {
        value: 2,
        message: 'El nombre debe tener al menos 2 caracteres'
      },
      required: { value: true, message: 'Nombre(s) requeridos' }
    }
  },
  last_name: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'last_name',
    label: 'Apellido(s)',
    className: 'flex-1 min-w-32',
    placeholder: 'Apellido(s)',
    required: true,
    showLabel: false,
    validations: {
      minLength: {
        value: 2,
        message: 'Debe tener al menos 2 caracteres'
      },
      required: { value: true, message: 'Apellido(s) requeridos' }
    }
  },
  company: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'company',
    label: 'Empresa',
    className: 'flex-1 min-w-32',
    placeholder: 'Empresa',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Empresa requerida' }
    }
  },
  position: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'position',
    label: 'Puesto',
    className: 'flex-1 min-w-32',
    placeholder: 'Puesto',
    required: false,
    showLabel: false,
    validations: {}
  },
  phone: {
    type: 'input',
    subtype: 'tel',
    defaultValue: '',
    name: 'phone',
    label: 'Teléfono',
    className: 'w-full min-w-full',
    placeholder: 'Teléfono',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Teléfono requerido' },
      pattern: {
        value: /^[0-9\s+]*$/,
        message: 'El teléfono no es válido'
      },
      minLength: {
        value: 14,
        message: 'El teléfono debe tener al menos 12 dígitos'
      },
      maxLength: {
        value: 16,
        message: 'El teléfono debe tener máximo 14 dígitos'
      }
    }
  },
  fix_number_phone: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'fix_number_phone',
    label: 'Número Fijo',
    className: 'flex-1 min-w-32',
    placeholder: 'Número Fijo',
    required: true,
    showLabel: false,
    validations: {
      pattern: {
        value: /^[0-9\s+]*$/,
        message: 'El teléfono no es válido'
      },
      required: { value: true, message: 'Número fijo requerido' }
    }
  },
  email: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'email',
    label: 'Correo',
    className: 'w-full min-w-full',
    placeholder: 'Correo',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Correo requerido' },
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'El correo electrónico no es válido'
      }
    }
  },
  message: {
    type: 'textarea',
    subtype: 'textarea',
    defaultValue: '',
    name: 'message',
    label: 'Mensaje',
    className: 'w-full min-w-full',
    placeholder: 'Mensaje',
    required: false,
    showLabel: false,
    validations: {}
  },
  residence: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'residence',
    label: 'Residencia',
    className: 'w-full min-w-full',
    placeholder: 'Lugar de Residencia (Ciudad / Estado)',
    required: false,
    showLabel: false,
    validations: {}
  },
  role: {
    type: 'select',
    subtype: 'select',
    defaultValue: '',
    name: 'role',
    label: 'Rol',
    className: 'w-full min-w-full',
    placeholder: 'Rol',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Rol requerido' }
    },
    options: [
      {
        value: '',
        label: 'Rol'
      },
      {
        value: 'ACCOUNTING_EXECUTIVE',
        label: 'Ejecutivo de Contabilidad'
      },
      {
        value: 'ADMINISTRATOR',
        label: 'Administrador'
      },
      {
        value: 'MARKETING_EXECUTIVE',
        label: 'Ejecutivo de Marketing'
      },
      {
        value: 'SALES_EXECUTIVE',
        label: 'Ejecutivo de Ventas'
      },
      {
        value: 'SALES_MANAGER',
        label: 'Gerente de Ventas'
      }
    ] as ISelectOption[]
  },
  accept_terms: {
    type: 'checkbox',
    subtype: 'privacy_policy',
    defaultValue: false,
    name: 'accept_terms',
    label: 'He leído la Política de Privacidad y doy mi consentimiento para el tratamiento de mis datos personales.',
    className: 'w-full min-w-full',
    placeholder: '',
    required: true,
    showLabel: true,
    validations: {
      required: { value: true, message: 'Debe aceptar la Política de Privacidad' }
    }
  },
  cv_resume: {
    type: 'input',
    subtype: 'file',
    defaultValue: undefined,
    name: 'cv_resume',
    label: 'CV',
    className: 'w-full min-w-full',
    placeholder: 'Adjuntar CV',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'El CV es requerido' }
    }
  },
  salary_expectation: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'salary_expectation',
    label: 'Salario Mensual Deseado (Después de impuesto)',
    className: 'w-full min-w-full',
    placeholder: 'Salario Mensual Deseado (Después de Impuestos)',
    required: false,
    showLabel: false,
    validations: {}
  },
  identification_card: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'identification_card',
    label: 'Número de Cédula Profesional',
    className: 'w-full min-w-full',
    placeholder: 'Número de Cédula Profesional',
    required: false,
    showLabel: false,
    validations: {}
  },
  years_of_experience: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'years_of_experience',
    label: 'Años de Experiencia en Puesto Similar',
    className: 'w-full min-w-full',
    placeholder: 'Años de Experiencia en Puesto Similar',
    required: false,
    showLabel: false,
    validations: {}
  },
  has_more_than_2_years_of_experience: {
    type: 'checkbox',
    subtype: 'has_more_than_2_years_of_experience',
    defaultValue: false,
    name: 'has_more_than_2_years_of_experience',
    label: '¿Cuenta con 2 o Más Referencias Laborales Comprobables?',
    className: 'w-full min-w-full',
    placeholder: '',
    required: false,
    showLabel: true,
    validations: {}
  },
  aimed_at: {
    type: 'select',
    subtype: 'select',
    defaultValue: '',
    name: 'aimed_at',
    label: 'Este Curso es Para:',
    className: 'w-full min-w-full',
    placeholder: 'Este Curso es Para:',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Campo requerido' }
    },
    options: [
      {
        value: '',
        label: 'Este Curso es Para:'
      },
      {
        value: 'company',
        label: 'Empresa'
      },
      {
        value: 'individual',
        label: 'Individual'
      }
    ]
  },
  username: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'username',
    label: 'Nombre de Usuario',
    className: 'w-full min-w-full',
    placeholder: 'Nombre de Usuario',
    required: false,
    showLabel: false,
    validations: {}
  },
  password: {
    type: 'input',
    subtype: 'password',
    defaultValue: '',
    name: 'password',
    label: 'Contraseña',
    className: 'w-full min-w-full',
    placeholder: 'Contraseña',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Contraseña requerida' }
    }
  },
  oldPassword: {
    type: 'input',
    subtype: 'password',
    defaultValue: '',
    name: 'oldPassword',
    label: 'Contraseña anterior',
    className: 'w-full min-w-full',
    placeholder: 'Contraseña anterior',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Contraseña requerida' }
    }
  },
  newPassword: {
    type: 'input',
    subtype: 'password',
    defaultValue: '',
    name: 'newPassword',
    label: 'Nueva Contraseña',
    className: 'w-full min-w-full',
    placeholder: 'Nueva Contraseña',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Contraseña requerida' }
    }
  },
  token: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'token',
    label: 'Token',
    className: 'w-full min-w-full',
    placeholder: 'Token',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Token requerido' }
    }
  }
}

export type RequestedFormsConfigType = Array<InputName | IInputConfigurationPartial>
export const useMergeFormsConfig = (requestedFormsConfig: RequestedFormsConfigType) => {
  const mergedFormsConfig = requestedFormsConfig.map((formConfig: InputName | IInputConfigurationPartial) => {
    if (typeof formConfig === 'string') {
      return { ...AvailableInputConfigurations[formConfig] }
    }
    const inputName = formConfig.name as InputName
    return { ...AvailableInputConfigurations[inputName], ...formConfig }
  })
  return mergedFormsConfig
}
